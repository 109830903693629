interface Props {
  text: String;
  mode: 'Purple' | 'Grey' | 'Disable';
  id?: string
}

function Button({ text, mode, id="button" }: Props) {
  const ModeClasses = {
    Purple: 'hover:bg-transparent hover:text-brand bg-brand text-[#ECECEC] border-brand',
    Grey: 'bg-[#715ba81a] text-brand hover:shadow-lg',
    Disable: 'bg-gray-300 text-white ',
  };

  return (
    <button
     id={id}
      className={`transition-all duration-500  sm:text-lg  font-bold rounded-full px-16 sm:px-24 leading-9 sm:leading-[55px] border ${ModeClasses[mode]} `}
    >
      {text}
    </button>
  );
}
export default Button;
