import S from "../../../assets/reviewPage/componentTwo/S.svg"
import C from "../../../assets/reviewPage/componentTwo/C.svg"
import K from "../../../assets/reviewPage/componentTwo/K.svg"
import D from "../../../assets/reviewPage/componentTwo/D.svg"
import N from "../../../assets/reviewPage/componentTwo/N.svg"
import R from "../../../assets/reviewPage/componentTwo/R.svg"
import A from "../../../assets/reviewPage/componentTwo/A.svg"
import Y from "../../../assets/reviewPage/componentTwo/Y.svg"
import G from "../../../assets/reviewPage/componentTwo/G.svg"
import B from "../../../assets/reviewPage/componentTwo/B.svg"

export const ReviewsData =[
    {
        image:C,
        name:"Chris Jackson",
        description:"I had an extremely positive experience with getting a HELOC from NB. It did take a little longer than expected and there was some back and forth between the lawyer and lender. However, I couldn't have asked for a better agent."
    },
    {
        image:D,
        name:"Denise McLean",
        description:"We highly recommend Nuborrow to take care of your finances.  They prove to be professional especially Haiey as she is very compassionate and on the ball with any of our questions and digs right to the source while giving her 💯 percent helping us with everything."
    },
    {
        image:N,
        name:"NhorsgP",
        description:"From the moment I applied on line, I received my call the next day from a friendly and knowledgeable person. He worked with us through the process of securing the mortgage to purchase the home we already live in."
    },
    {
        image:S,
        name:"Susan",
        description:"From our first conversation, Tanya L proved to be the ideal guide. Professional, knowledgeable, and passionate, she made the mortgage process seamless. Her attention to detail and follow-up were exceptional, securing us significant savings."
    },
    {
        image:R,
        name:"Rachel Baillargeon",
        description:"My experience with Matthew Ruocco as my broker was incredibly smooth and effortless. He prioritized my time and concerns, ensuring a timely and efficient process. I never felt like an inconvenience; rather, he made me feel heard and valued throughout the entire experience."
    },
    {
        image:K,
        name:"Keith Waterman",
        description:"Absolutely incredible experience from start to finish. Mathew my agent took any guesswork out of the experience, as he explained everything in easy to understand terms. If you are looking for a home equity loan or otherwise. You are in great hands with Nuborrow"
    },
    {
        image:Y,
        name:"Yvonne Johansen",
        description:"Professional company to work with. The experience was made easy by all involved. There was no pressure, but someone was always available to answer any questions I had."
    },
    {
        image:A,
        name:"Aaron Merkestyn",
        description:"Rebecca with Nuborrow has to be one of the best consultants I have ever used for my lending needs. She is knowledgeable and very professional. Her expertise and kindness are above all else. All who spoke to me made the process go by so easily. Thanks to Rebeca and the Nuborrow team."
    },
    {
        image:D,
        name:"Diane Watson",
        description:"Danielle Opong was our broker and was helped us through every step of the way, my situation was a mess and not once did they waiver away from it. I owe a lot to this company. Thank you"
    },
    {
        image:G,
        name:"Glenn Oliver",
        description:"I was with Megan Robertson she helped me throw it all from start to finish she was there every time I had a question very pleasant to talk to look forward to doing business with Megan again"
    },
    {
        image:B,
        name:"Bonnie Laroche",
        description:"Our experience with Danielle Opong was great, fast, friendly and efficient. She was informative and meet all our mortage needs. We will highly recommend Nuborrow and Danielle to all.  Thanks again!"
    },
    {
        image:C,
        name:"Chef Cory",
        description:"Working with Nuborrow was a breeze. I especially want to thank Rob Beeser for all his amazing effort and hard work. He was extremely helpful and easy to work with throughout the whole process. I also want to thank Adam for his help. I would highly recommend Nuborrow for any mortgage and heloc needs. ⭐️ ⭐️ ⭐️ ⭐️ ⭐️"
    },
]